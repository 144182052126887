import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Avatar,
  Divider,
} from "@mui/material";
import { getUserPicks } from "../services/picksService";
import { getUserById } from "../services/userService";
import { auth } from "../firebase";

const ComparisonPage = () => {
  const { userId } = useParams(); // userId from the URL parameter
  const [currentUser, setCurrentUser] = useState({});
  const [competitor, setCompetitor] = useState({});
  const [currentUserPicks, setCurrentUserPicks] = useState([]);
  const [competitorPicks, setCompetitorPicks] = useState([]);
  const [results, setResults] = useState([]);

  useEffect(() => {
    const fetchPicksAndResults = async () => {
      try {
        const currentUserId = auth.currentUser?.uid;
        if (!currentUserId) return;

        const currentUserData = await getUserById(currentUserId);
        setCurrentUser(currentUserData);

        const competitorData = await getUserById(userId);
        setCompetitor(competitorData);

        const currentUserPickData = await getUserPicks(currentUserId);
        setCurrentUserPicks(currentUserPickData);

        const competitorPickData = await getUserPicks(userId);
        setCompetitorPicks(competitorPickData);

        const resultsResponse = await fetch("/results.json");
        const resultsData = await resultsResponse.json();
        setResults(resultsData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchPicksAndResults();
  }, [userId]);

  const formatEventName = (name) => {
    let formattedName = name.toLowerCase();
    formattedName = formattedName.replace("MEN S-", "Men's ");
    formattedName = formattedName.replace("WOMEN S-", "Women's ");
    formattedName = formattedName.replace("4-x-", "4x");
    return formattedName.replace(/-/g, " ");
  };

  const getUpcomingComparisonData = () => {
    return currentUserPicks
      .filter(
        (userPick) => !results.some((result) => result.event === userPick.event)
      )
      .map((userPick) => {
        const competitorPick = competitorPicks.find(
          (pick) => pick.event === userPick.event
        );

        return {
          event: userPick.event,
          userGold: userPick.gold,
          userSilver: userPick.silver,
          userBronze: userPick.bronze,
          competitorGold: competitorPick?.gold || "N/A",
          competitorSilver: competitorPick?.silver || "N/A",
          competitorBronze: competitorPick?.bronze || "N/A",
        };
      });
  };

  const calculatePointsDifference = (userPick, competitorPick, medal) => {
    const pointsForCorrectMedal = {
      gold: 5,
      silver: 3,
      bronze: 2,
    };

    if (userPick === competitorPick) {
      return 0; // No points gained or lost if both have the same pick
    } else if (competitorPick === "N/A") {
      return pointsForCorrectMedal[medal]; // Full points if competitor has no pick
    } else {
      return pointsForCorrectMedal[medal] - 1; // Deduct 1 point if competitor has a near miss
    }
  };

  const getCellStyle = (pick, result, medal) => {
    if (!result) return {};

    const correctMedalist = Array.isArray(result[medal])
      ? result[medal].includes(pick)
      : pick === result[medal];

    if (correctMedalist) {
      if (medal === "gold") return { backgroundColor: "#FFD700" };
      if (medal === "silver") return { backgroundColor: "#C0C0C0" };
      if (medal === "bronze") return { backgroundColor: "#CD7F32" };
    } else if (
      (Array.isArray(result.gold) && result.gold.includes(pick)) ||
      (Array.isArray(result.silver) && result.silver.includes(pick)) ||
      (Array.isArray(result.bronze) && result.bronze.includes(pick))
    ) {
      return { backgroundColor: "#ADD8E6" };
    }
    return {};
  };

  return (
    <Box sx={{ padding: { xs: "8px", sm: "16px" } }}>
      <Typography
        variant="h4"
        gutterBottom
        sx={{ fontSize: { xs: "1.5rem", sm: "2rem" } }}
      >
        Compare Picks: {currentUser.name} vs. {competitor.name}
      </Typography>
      <Grid container spacing={2}>
        {getUpcomingComparisonData().map((data, index) => (
          <Grid item xs={12} key={index}>
            <Card variant="outlined" sx={{ marginBottom: 2 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {formatEventName(data.event).toUpperCase()}
                </Typography>
                <Divider sx={{ marginBottom: 2 }} />
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1">
                      {currentUser.name}
                    </Typography>
                    <Box
                      display="flex"
                      alignItems="center"
                      sx={{
                        ...getCellStyle(data.userGold, data.result, "gold"),
                        mb: 1,
                        fontSize: { xs: "0.8rem", sm: "1rem" },
                      }}
                    >
                      <Avatar
                        sx={{
                          bgcolor: "#FFD700",
                          mr: 1,
                          width: { xs: 24, sm: 32 },
                          height: { xs: 24, sm: 32 },
                        }}
                      >
                        G
                      </Avatar>
                      <Typography>{data.userGold}</Typography>
                      <Typography sx={{ ml: "auto", fontWeight: "bold" }}>
                        {calculatePointsDifference(
                          data.userGold,
                          data.competitorGold,
                          "gold"
                        ) > 0
                          ? "+"
                          : ""}
                        {calculatePointsDifference(
                          data.userGold,
                          data.competitorGold,
                          "gold"
                        )}
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      sx={{
                        ...getCellStyle(data.userSilver, data.result, "silver"),
                        mb: 1,
                        fontSize: { xs: "0.8rem", sm: "1rem" },
                      }}
                    >
                      <Avatar
                        sx={{
                          bgcolor: "#C0C0C0",
                          mr: 1,
                          width: { xs: 24, sm: 32 },
                          height: { xs: 24, sm: 32 },
                        }}
                      >
                        S
                      </Avatar>
                      <Typography>{data.userSilver}</Typography>
                      <Typography sx={{ ml: "auto", fontWeight: "bold" }}>
                        {calculatePointsDifference(
                          data.userSilver,
                          data.competitorSilver,
                          "silver"
                        ) > 0
                          ? "+"
                          : ""}
                        {calculatePointsDifference(
                          data.userSilver,
                          data.competitorSilver,
                          "silver"
                        )}
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      sx={{
                        ...getCellStyle(data.userBronze, data.result, "bronze"),
                        fontSize: { xs: "0.8rem", sm: "1rem" },
                      }}
                    >
                      <Avatar
                        sx={{
                          bgcolor: "#CD7F32",
                          mr: 1,
                          width: { xs: 24, sm: 32 },
                          height: { xs: 24, sm: 32 },
                        }}
                      >
                        B
                      </Avatar>
                      <Typography>{data.userBronze}</Typography>
                      <Typography sx={{ ml: "auto", fontWeight: "bold" }}>
                        {calculatePointsDifference(
                          data.userBronze,
                          data.competitorBronze,
                          "bronze"
                        ) > 0
                          ? "+"
                          : ""}
                        {calculatePointsDifference(
                          data.userBronze,
                          data.competitorBronze,
                          "bronze"
                        )}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1">
                      {competitor.name}
                    </Typography>
                    <Box
                      display="flex"
                      alignItems="center"
                      sx={{
                        ...getCellStyle(
                          data.competitorGold,
                          data.result,
                          "gold"
                        ),
                        mb: 1,
                        fontSize: { xs: "0.8rem", sm: "1rem" },
                      }}
                    >
                      <Avatar
                        sx={{
                          bgcolor: "#FFD700",
                          mr: 1,
                          width: { xs: 24, sm: 32 },
                          height: { xs: 24, sm: 32 },
                        }}
                      >
                        G
                      </Avatar>
                      <Typography>{data.competitorGold}</Typography>
                    </Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      sx={{
                        ...getCellStyle(
                          data.competitorSilver,
                          data.result,
                          "silver"
                        ),
                        mb: 1,
                        fontSize: { xs: "0.8rem", sm: "1rem" },
                      }}
                    >
                      <Avatar
                        sx={{
                          bgcolor: "#C0C0C0",
                          mr: 1,
                          width: { xs: 24, sm: 32 },
                          height: { xs: 24, sm: 32 },
                        }}
                      >
                        S
                      </Avatar>
                      <Typography>{data.competitorSilver}</Typography>
                    </Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      sx={{
                        ...getCellStyle(
                          data.competitorBronze,
                          data.result,
                          "bronze"
                        ),
                        fontSize: { xs: "0.8rem", sm: "1rem" },
                      }}
                    >
                      <Avatar
                        sx={{
                          bgcolor: "#CD7F32",
                          mr: 1,
                          width: { xs: 24, sm: 32 },
                          height: { xs: 24, sm: 32 },
                        }}
                      >
                        B
                      </Avatar>
                      <Typography>{data.competitorBronze}</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ComparisonPage;
