import React, { useState, useEffect } from "react";
import { Box, Card, CardContent, Typography, Grid } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useNavigate } from "react-router-dom";
import { getUserPicks } from "../services/picksService";
import { auth } from "../firebase";
import CountdownTimer from "../components/CountdownTimer";

const EventsPage = () => {
  const [events, setEvents] = useState([]);
  const userId = auth.currentUser?.uid;
  const [userPicks, setUserPicks] = useState({});
  const navigate = useNavigate();

  const firstEventStartTime = "2024-08-01T01:30:00-05:00";

  useEffect(() => {
    fetch("/olympics_data.json")
      .then((response) => response.json())
      .then((data) => {
        setEvents(data);
      })
      .catch((error) => console.error("Error fetching events data:", error));
  }, []);

  useEffect(() => {
    const fetchPicks = async () => {
      if (userId) {
        const userPicksData = await getUserPicks(userId);

        const picksData = {};
        userPicksData.forEach((pick) => {
          const { event, gold, silver, bronze } = pick;

          picksData[event] = gold && silver && bronze;
        });
        setUserPicks(picksData);
      }
    };
    fetchPicks();
  }, [events, userId]);

  const handleCardClick = (event) => {
    navigate(`/event/${encodeURIComponent(event.event)}`);
  };

  const formatEventName = (name) => {
    let formattedName = name.toLowerCase();
    formattedName = formattedName.replace("men s-", "Men's ");
    formattedName = formattedName.replace("/women s-", "Women's ");
    formattedName = formattedName.replace("4-x-", "4x");
    return formattedName.replace(/-/g, " ");
  };

  const renderEventCard = (event) => (
    <Card
      key={event.event}
      variant="outlined"
      sx={{
        margin: 2,
        padding: 2,
        borderWidth: userPicks[event.event] ? 3 : 1,
        borderColor: userPicks[event.event] ? "green" : "grey",
        backgroundColor: "white", // Background color for visibility
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Add shadow for better visibility
        cursor: "pointer",
        transition: "transform 0.2s ease-in-out",
        "&:hover": {
          transform: "scale(1.02)",
          boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
        },
        textAlign: "center", // Center align text
      }}
      onClick={() => handleCardClick(event)}
    >
      <CardContent>
        <Typography
          variant="h6"
          sx={{ color: "inherit", textDecoration: "none", fontSize: "0.5rem" }}
        >
          {formatEventName(event.event).toUpperCase()}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 1,
          }}
        >
          {userPicks[event.event] ? (
            <CheckCircleIcon sx={{ color: "green" }} />
          ) : (
            <CheckCircleOutlineIcon sx={{ color: "grey" }} />
          )}
        </Box>
      </CardContent>
    </Card>
  );

  return (
    <Box sx={{ padding: "16px" }}>
      <Box sx={{ marginTop: 4, textAlign: "center" }}>
        <Typography variant="h5" gutterBottom>
          Time Remaining to Lock In Your Picks
        </Typography>
        <CountdownTimer targetDate={firstEventStartTime} />
      </Box>
      <Typography variant="h4" gutterBottom>
        Events
      </Typography>
      <Grid container spacing={2}>
        {events.map((event, index) => (
          <Grid item xs={6} key={event.event}>
            {renderEventCard(event)}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default EventsPage;
