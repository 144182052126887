import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
} from "@mui/material";
import { getUserPicks } from "../services/picksService";
import { getUsers } from "../services/leaderboardService";
import { auth } from "../firebase";

const LeaderboardTestPage = () => {
  const [leaderboard, setLeaderboard] = useState([]);
  const userId = auth.currentUser?.uid;

  const fetchLeaderboardData = async () => {
    try {
      const users = await getUsers();
      const userPickPromises = users.map((user) => getUserPicks(user.id));
      const allUserPicks = await Promise.all(userPickPromises);

      const resultsResponse = await fetch(`/results.json?v=${new Date().getTime()}`);
      const resultsData = await resultsResponse.json();

      const processedLeaderboard = users
        .map((user, index) => {
          const userPicks = allUserPicks[index];
          const hasAllPicks =
            userPicks.length === 48 &&
            userPicks.every((pick) => pick.gold && pick.silver && pick.bronze);
          if (hasAllPicks) {
            const { totalGold, totalSilver, totalBronze, totalPoints } =
              calculateScores(userPicks, resultsData);
            return {
              id: user.id,
              name: user.name,
              gold: totalGold,
              silver: totalSilver,
              bronze: totalBronze,
              totalPoints,
              avatar: user.avatar,
            };
          }
          return null;
        })
        .filter(Boolean)
        .sort((a, b) => b.totalPoints - a.totalPoints); // Sort by total points

      setLeaderboard(processedLeaderboard);
    } catch (error) {
      console.error("Error fetching leaderboard data:", error);
    }
  };

  useEffect(() => {
    fetchLeaderboardData();
  }, []);

  const calculateScores = (userPicks, results) => {
    let totalGold = 0;
    let totalSilver = 0;
    let totalBronze = 0;
    let totalPoints = 0;

    userPicks.forEach((pick) => {
      const eventResult = results.find((result) => result.event === pick.event);
      if (eventResult) {
        if (pick.gold === eventResult.gold.name) totalGold += 1;
        if (pick.silver === eventResult.silver.name) totalSilver += 1;
        if (pick.bronze === eventResult.bronze.name) totalBronze += 1;

        if (pick.gold === eventResult.gold.name) totalPoints += 5;
        else if (
          pick.gold === eventResult.silver.name ||
          pick.gold === eventResult.bronze.name
        )
          totalPoints += 1;

        if (pick.silver === eventResult.silver.name) totalPoints += 3;
        else if (
          pick.silver === eventResult.gold.name ||
          pick.silver === eventResult.bronze.name
        )
          totalPoints += 1;

        if (pick.bronze === eventResult.bronze.name) totalPoints += 2;
        else if (
          pick.bronze === eventResult.gold.name ||
          pick.bronze === eventResult.silver.name
        )
          totalPoints += 1;

        if (
          pick.gold === eventResult.gold.name &&
          pick.silver === eventResult.silver.name &&
          pick.bronze === eventResult.bronze.name
        ) {
          totalPoints += 5; // Bonus points for correctly predicting all three medalists in the correct order
        }
      }
    });

    return { totalGold, totalSilver, totalBronze, totalPoints };
  };

  const stringToColor = (string) => {
    let hash = 0;
    let i;
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = "#";
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
  };

  const stringAvatar = (name) => {
    const nameParts = name.split(" ");
    const initials =
      nameParts.length > 1
        ? `${nameParts[0][0]}${nameParts[1][0]}`
        : `${nameParts[0][0]}`;
    return {
      sx: {
        bgcolor: stringToColor(name),
        color: "#fff",
      },
      children: initials,
    };
  };

  return (
    <Box sx={{ padding: { xs: "8px", sm: "16px" } }}>
      <Typography
        variant="h4"
        gutterBottom
        sx={{ fontSize: { xs: "1.5rem", sm: "2rem" } }}
      >
        Leaderboard
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ width: "100%" }}>
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                sx={{
                  padding: "4px",
                  fontSize: { xs: "0.6rem", sm: "0.8rem" },
                }}
              >
                Name
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  padding: "4px",
                  fontSize: { xs: "0.6rem", sm: "0.8rem" },
                }}
              >
                <Box className="rotate-header">Gold</Box>
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  padding: "4px",
                  fontSize: { xs: "0.6rem", sm: "0.8rem" },
                }}
              >
                <Box className="rotate-header">Silver</Box>
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  padding: "4px",
                  fontSize: { xs: "0.6rem", sm: "0.8rem" },
                }}
              >
                <Box className="rotate-header">Bronze</Box>
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  padding: "4px",
                  fontSize: { xs: "0.6rem", sm: "0.8rem" },
                }}
              >
                <Box className="rotate-header">Total</Box>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {leaderboard.map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  backgroundColor:
                    row.id === userId ? "rgba(0, 128, 0, 0.1)" : "inherit",
                }}
              >
                <TableCell
                  align="center"
                  sx={{
                    padding: "4px",
                    fontSize: { xs: "0.6rem", sm: "0.8rem" },
                  }}
                >
                  <Avatar
                    {...stringAvatar(row.name)}
                    alt={row.name}
                    sx={{
                      marginRight: 1,
                      width: { xs: 24, sm: 40 },
                      height: { xs: 24, sm: 40 },
                    }}
                  />
                  {row.name}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    padding: "4px",
                    fontSize: { xs: "0.6rem", sm: "0.8rem" },
                    backgroundColor: "#FFD700",
                    borderRadius: "50%",
                    width: "30px",
                    height: "30px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {row.gold}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    padding: "4px",
                    fontSize: { xs: "0.6rem", sm: "0.8rem" },
                    backgroundColor: "#C0C0C0",
                    borderRadius: "50%",
                    width: "30px",
                    height: "30px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {row.silver}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    padding: "4px",
                    fontSize: { xs: "0.6rem", sm: "0.8rem" },
                    backgroundColor: "#CD7F32",
                    borderRadius: "50%",
                    width: "30px",
                    height: "30px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {row.bronze}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    padding: "4px",
                    fontSize: { xs: "0.6rem", sm: "0.8rem" },
                  }}
                >
                  {row.totalPoints}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default LeaderboardTestPage;
