import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Digital-7+Mono&display=swap');

  body {
    font-family: 'Digital-7 Mono', sans-serif;
  }
`;

export default GlobalStyle;
