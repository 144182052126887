import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  FormControlLabel,
  Radio,
  TextField,
  List,
  ListItem,
  ListItemText,
  Button,
  TableSortLabel,
  useMediaQuery,
} from "@mui/material";
import { getUserPicks, saveUserPicks } from "../services/picksService";
import { auth } from "../firebase";
import { useConsolidateRelayTeams } from "../hooks/useConsolidateRelayTeams";

const countryCodes = {
  Afghanistan: "AFG",
  Albania: "ALB",
  Algeria: "ALG",
  "American Samoa": "ASA",
  Andorra: "AND",
  Angola: "ANG",
  "Antigua and Barbuda": "ANT",
  Argentina: "ARG",
  Armenia: "ARM",
  Australia: "AUS",
  Austria: "AUT",
  Azerbaijan: "AZE",
  Bahamas: "BAH",
  Bahrain: "BRN",
  Bangladesh: "BAN",
  Barbados: "BAR",
  Belarus: "BLR",
  Belgium: "BEL",
  Belize: "BIZ",
  Benin: "BEN",
  Bhutan: "BHU",
  Bolivia: "BOL",
  "Bosnia and Herzegovina": "BIH",
  Botswana: "BOT",
  Brazil: "BRA",
  "Brunei Darussalam": "BRU",
  Bulgaria: "BUL",
  "Burkina Faso": "BUR",
  Burundi: "BDI",
  "Cabo Verde": "CPV",
  Cambodia: "CAM",
  Cameroon: "CMR",
  Canada: "CAN",
  "Cayman Islands": "CAY",
  "Centr Afric Rep": "CAF",
  Chad: "CHA",
  Chile: "CHI",
  China: "CHN",
  Colombia: "COL",
  Comoros: "COM",
  Congo: "CGO",
  "Costa Rica": "CRC",
  Croatia: "CRO",
  Cuba: "CUB",
  Cyprus: "CYP",
  Czechia: "CZE",
  Denmark: "DEN",
  Djibouti: "DJI",
  "DR Congo": "COD",
  Dominica: "DMA",
  "Dominican Republic": "DOM",
  Ecuador: "ECU",
  Egypt: "EGY",
  "El Salvador": "ESA",
  EOR: "EOR",
  "Equatorial Guinea": "GEQ",
  Eritrea: "ERI",
  Estonia: "EST",
  Eswatini: "SWZ",
  Ethiopia: "ETH",
  Fiji: "FIJ",
  Finland: "FIN",
  France: "FRA",
  Gabon: "GAB",
  Gambia: "GAM",
  Georgia: "GEO",
  Germany: "GER",
  Ghana: "GHA",
  "Great Britain": "GBR",
  Greece: "GRE",
  Grenada: "GRN",
  Guam: "GUM",
  Guatemala: "GUA",
  Guinea: "GUI",
  "Guinea-Bissau": "GBS",
  Guyana: "GUY",
  Haiti: "HAI",
  Honduras: "HON",
  "Hong Kong, China": "HKG",
  Hungary: "HUN",
  Iceland: "ISL",
  India: "IND",
  Indonesia: "INA",
  "IR Iran": "IRI",
  Iraq: "IRQ",
  Ireland: "IRL",
  Israel: "ISR",
  Italy: "ITA",
  "Côte d'Ivoire": "CIV",
  Jamaica: "JAM",
  Japan: "JPN",
  Jordan: "JOR",
  Kazakhstan: "KAZ",
  Kenya: "KEN",
  Kiribati: "KIR",
  Kuwait: "KUW",
  Kyrgyzstan: "KGZ",
  "Lao PDR": "LAO",
  Latvia: "LAT",
  Lebanon: "LBN",
  Lesotho: "LES",
  Liberia: "LBR",
  Libya: "LBA",
  Liechtenstein: "LIE",
  Lithuania: "LTU",
  Luxembourg: "LUX",
  Madagascar: "MAD",
  Malawi: "MAW",
  Malaysia: "MAS",
  Maldives: "MDV",
  Mali: "MLI",
  Malta: "MLT",
  "Marshall Islands": "MHL",
  Mauritania: "MTN",
  Mauritius: "MRI",
  Mexico: "MEX",
  Micronesia: "FSM",
  Moldova: "MDA",
  Monaco: "MON",
  Mongolia: "MGL",
  Montenegro: "MNE",
  Morocco: "MAR",
  Mozambique: "MOZ",
  Myanmar: "MYA",
  Namibia: "NAM",
  Nauru: "NRU",
  Nepal: "NEP",
  Netherlands: "NED",
  "New Zealand": "NZL",
  Nicaragua: "NCA",
  Niger: "NIG",
  Nigeria: "NGR",
  "North Macedonia": "MKD",
  Norway: "NOR",
  Oman: "OMA",
  Pakistan: "PAK",
  Palau: "PLW",
  Palestine: "PLE",
  Panama: "PAN",
  "Papua New Guinea": "PNG",
  Paraguay: "PAR",
  Peru: "PER",
  Philippines: "PHI",
  Poland: "POL",
  Portugal: "POR",
  "Puerto Rico": "PUR",
  Qatar: "QAT",
  Romania: "ROU",
  Russia: "RUS",
  Rwanda: "RWA",
  "St Kitts and Nevis": "SKN",
  "Saint Lucia": "LCA",
  "St Vincent and the Grenadines": "VIN",
  Samoa: "SAM",
  "San Marino": "SMR",
  "Sao Tome & Principe": "STP",
  "Saudi Arabia": "KSA",
  Senegal: "SEN",
  Serbia: "SRB",
  Seychelles: "SEY",
  "Sierra Leone": "SLE",
  Singapore: "SGP",
  Slovakia: "SVK",
  Slovenia: "SLO",
  "Solomon Islands": "SOL",
  Somalia: "SOM",
  "South Africa": "RSA",
  "South Korea": "KOR",
  "South Sudan": "SSD",
  Spain: "ESP",
  "Sri Lanka": "SRI",
  Sudan: "SUD",
  Suriname: "SUR",
  Sweden: "SWE",
  Switzerland: "SUI",
  Syria: "SYR",
  "Chinese Taipei": "TPE",
  Tajikistan: "TJK",
  Tanzania: "TAN",
  Thailand: "THA",
  "Timor-Leste": "TLS",
  Togo: "TOG",
  Tonga: "TGA",
  "Trinidad and Tobago": "TTO",
  Tunisia: "TUN",
  Türkiye: "TUR",
  Turkmenistan: "TKM",
  Tuvalu: "TUV",
  Uganda: "UGA",
  Ukraine: "UKR",
  "UA Emirates": "UAE",
  "United Kingdom": "GBR",
  "United States": "USA",
  Uruguay: "URU",
  Uzbekistan: "UZB",
  Vanuatu: "VAN",
  Venezuela: "VEN",
  "Virgin Islands, B": "IVB",
  Vietnam: "VIE",
  Yemen: "YEM",
  Zambia: "ZAM",
  Zimbabwe: "ZIM",
};

const EventPage = () => {
  const { eventName } = useParams();
  const [event, setEvent] = useState(null);
  const [athletes, setAthletes] = useState([]);
  const [picks, setPicks] = useState({ gold: "", silver: "", bronze: "" });
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredAthletes, setFilteredAthletes] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const userId = auth.currentUser?.uid;
  const [selectedAthlete, setSelectedAthlete] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  const isMobile = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    fetch("/olympics_data.json")
      .then((response) => response.json())
      .then((data) => {
        const eventData = data.find(
          (event) => event.event === decodeURIComponent(eventName)
        );
        setEvent(eventData);
        setAthletes(eventData.athletes);
      });
  }, [eventName]);

  const consolidatedAthletes = useConsolidateRelayTeams(athletes, eventName);

  useEffect(() => {
    if (event) {
      getUserPicks(userId).then((existingPicks) => {
        const userEventPicks = existingPicks.find(
          (pick) => pick.event === event.event
        );
        if (userEventPicks) {
          setPicks(userEventPicks);
        }
      });
    }
  }, [event, userId]);

  const handlePickChange = (athlete, medal) => {
    setPicks((prevPicks) => {
      const newPicks = { ...prevPicks };
      for (let key in newPicks) {
        if (newPicks[key] === athlete.name) {
          newPicks[key] = "";
        }
      }
      newPicks[medal] = athlete.name;
      saveUserPicks(userId, event.event, newPicks);
      return newPicks;
    });

    // Clear search filter but keep sorting
    setSearchTerm("");
    const updatedAthletes = [...consolidatedAthletes];

    // Apply the current sorting
    if (sortConfig.key) {
      updatedAthletes.sort((a, b) => {
        if (a[sortConfig.key] === "N/A") return 1; // N/A values should be at the bottom
        if (b[sortConfig.key] === "N/A") return -1;

        if (
          sortConfig.key === "seasonBest" ||
          sortConfig.key === "personalBest" ||
          sortConfig.key === "worldRank"
        ) {
          const aValue = a[sortConfig.key]
            ? parseFloat(a[sortConfig.key].replace(/:/g, "."))
            : NaN;
          const bValue = b[sortConfig.key]
            ? parseFloat(b[sortConfig.key].replace(/:/g, "."))
            : NaN;

          if (isNaN(aValue)) return 1;
          if (isNaN(bValue)) return -1;

          return sortConfig.direction === "asc"
            ? aValue - bValue
            : bValue - aValue;
        } else {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "asc" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "asc" ? 1 : -1;
          }
          return 0;
        }
      });
    }

    setFilteredAthletes(updatedAthletes);
    setSelectedAthlete(null);
    setShowDropdown(false);
  };

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    if (term.length > 0) {
      const filtered = consolidatedAthletes.filter(
        (athlete) =>
          athlete.name.toLowerCase().includes(term.toLowerCase()) ||
          athlete.country.toLowerCase().includes(term.toLowerCase())
      );
      setFilteredAthletes(filtered);
      setShowDropdown(true);
    } else {
      setFilteredAthletes(consolidatedAthletes);
      setShowDropdown(false);
    }
  };

  const handleSearchKeyDown = (e) => {
    if (e.key === "Enter") {
      e.target.blur();
    }
  };

  const handleAthleteSelect = (athlete) => {
    setSelectedAthlete(athlete);
    setSearchTerm(athlete.name);
    setShowDropdown(false);
    setFilteredAthletes([athlete]);
  };

  const resetSearch = () => {
    setSearchTerm("");
    setFilteredAthletes(consolidatedAthletes);
    setSelectedAthlete(null);
    setShowDropdown(false);
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sortedAthletes = [...filteredAthletes].sort((a, b) => {
      const aValue = a[key];
      const bValue = b[key];

      // Ensure 'N/A' values are at the bottom
      if (aValue === "N/A") return 1;
      if (bValue === "N/A") return -1;

      // Handle sorting for seasonBest, personalBest, and worldRank
      if (
        key === "seasonBest" ||
        key === "personalBest" ||
        key === "worldRank"
      ) {
        const parseTime = (value) => {
          if (
            typeof value === "string" &&
            (value.includes(".") || value.includes(":"))
          ) {
            return parseFloat(value.replace(/:/g, "."));
          }
          return parseFloat(value);
        };

        const parsedAValue = parseTime(aValue);
        const parsedBValue = parseTime(bValue);

        if (isNaN(parsedAValue)) return 1;
        if (isNaN(parsedBValue)) return -1;

        return direction === "asc"
          ? parsedAValue - parsedBValue
          : parsedBValue - parsedAValue;
      } else {
        if (aValue < bValue) {
          return direction === "asc" ? -1 : 1;
        }
        if (aValue > bValue) {
          return direction === "asc" ? 1 : -1;
        }
        return 0;
      }
    });

    setFilteredAthletes(sortedAthletes);
  };

  useEffect(() => {
    setFilteredAthletes(consolidatedAthletes);
  }, [consolidatedAthletes]);

  if (!event) {
    return <div>Loading...</div>;
  }

  return (
    <Box sx={{ paddingTop: "64px", padding: { xs: "8px", sm: "16px" } }}>
      <Typography
        variant="h4"
        component="div"
        sx={{
          flexGrow: 1,
          marginTop: 2,
          fontSize: { xs: "1.2rem", sm: "1.5rem" },
        }}
      >
        {event.event.replace("/", "").replace("-", " ").toUpperCase()}
      </Typography>

      <TextField
        label="Search"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchTerm}
        onChange={handleSearchChange}
        onKeyDown={handleSearchKeyDown}
        onFocus={(e) => e.target.select()}
      />

      {showDropdown && (
        <Paper
          sx={{
            position: "absolute",
            zIndex: 1000,
            width: "100%",
            maxHeight: "200px",
            overflow: "auto",
          }}
        >
          <List>
            {filteredAthletes.map((athlete, index) => (
              <ListItem
                key={index}
                button
                onClick={() => handleAthleteSelect(athlete)}
              >
                <ListItemText
                  primary={athlete.name}
                  secondary={countryCodes[athlete.country] || athlete.country}
                />
              </ListItem>
            ))}
          </List>
        </Paper>
      )}

      <Box sx={{ marginTop: 2 }}>
        <Typography
          variant="h6"
          sx={{ fontSize: { xs: "1rem", sm: "1.25rem" } }}
        >
          Selected Picks
        </Typography>
        <Typography sx={{ fontSize: { xs: "0.8rem", sm: "1rem" } }}>
          Gold: {picks.gold}
        </Typography>
        <Typography sx={{ fontSize: { xs: "0.8rem", sm: "1rem" } }}>
          Silver: {picks.silver}
        </Typography>
        <Typography sx={{ fontSize: { xs: "0.8rem", sm: "1rem" } }}>
          Bronze: {picks.bronze}
        </Typography>
      </Box>
      <Paper sx={{ width: "100%", overflow: "auto", marginTop: 2 }}>
        <Table>
          <TableHead>
            <TableCell
              sx={{ padding: { xs: "1px" } }}
              sortDirection={
                sortConfig.key === "name" ? sortConfig.direction : false
              }
            >
              <TableSortLabel
                active={sortConfig.key === "name"}
                direction={
                  sortConfig.key === "name" ? sortConfig.direction : "asc"
                }
                onClick={() => handleSort("name")}
              >
                Name
              </TableSortLabel>
            </TableCell>
            {isMobile ? (
              <TableCell
                sx={{ padding: "1px" }}
                sortDirection={
                  sortConfig.key === "country" ? sortConfig.direction : false
                }
              >
                <TableSortLabel
                  active={sortConfig.key === "country"}
                  direction={
                    sortConfig.key === "country" ? sortConfig.direction : "asc"
                  }
                  onClick={() => handleSort("country")}
                >
                  Flag
                </TableSortLabel>
              </TableCell>
            ) : (
              <TableCell
                sortDirection={
                  sortConfig.key === "country" ? sortConfig.direction : false
                }
              >
                <TableSortLabel
                  active={sortConfig.key === "country"}
                  direction={
                    sortConfig.key === "country" ? sortConfig.direction : "asc"
                  }
                  onClick={() => handleSort("country")}
                >
                  Country
                </TableSortLabel>
              </TableCell>
            )}
            {isMobile ? <></> : <TableCell>Flag</TableCell>}
            {isMobile ? (
              <TableCell
                sx={{ padding: "1px" }}
                sortDirection={
                  sortConfig.key === "seasonBest" ? sortConfig.direction : false
                }
              >
                <TableSortLabel
                  active={sortConfig.key === "seasonBest"}
                  direction={
                    sortConfig.key === "seasonBest"
                      ? sortConfig.direction
                      : "asc"
                  }
                  onClick={() => handleSort("seasonBest")}
                >
                  SB
                </TableSortLabel>
              </TableCell>
            ) : (
              <TableCell
                sortDirection={
                  sortConfig.key === "seasonBest" ? sortConfig.direction : false
                }
              >
                <TableSortLabel
                  active={sortConfig.key === "seasonBest"}
                  direction={
                    sortConfig.key === "seasonBest"
                      ? sortConfig.direction
                      : "asc"
                  }
                  onClick={() => handleSort("seasonBest")}
                >
                  Season's Best
                </TableSortLabel>
              </TableCell>
            )}
            {isMobile ? (
              <TableCell
                sx={{ padding: "1px" }}
                sortDirection={
                  sortConfig.key === "personalBest"
                    ? sortConfig.direction
                    : false
                }
              >
                <TableSortLabel
                  active={sortConfig.key === "personalBest"}
                  direction={
                    sortConfig.key === "personalBest"
                      ? sortConfig.direction
                      : "asc"
                  }
                  onClick={() => handleSort("personalBest")}
                >
                  PB
                </TableSortLabel>
              </TableCell>
            ) : (
              <TableCell
                sortDirection={
                  sortConfig.key === "personalBest"
                    ? sortConfig.direction
                    : false
                }
              >
                <TableSortLabel
                  active={sortConfig.key === "personalBest"}
                  direction={
                    sortConfig.key === "personalBest"
                      ? sortConfig.direction
                      : "asc"
                  }
                  onClick={() => handleSort("personalBest")}
                >
                  Personal Best
                </TableSortLabel>
              </TableCell>
            )}
            {isMobile ? (
              <TableCell
                sx={{ padding: "1px" }}
                sortDirection={
                  sortConfig.key === "worldRank" ? sortConfig.direction : false
                }
              >
                <TableSortLabel
                  active={sortConfig.key === "worldRank"}
                  direction={
                    sortConfig.key === "worldRank"
                      ? sortConfig.direction
                      : "asc"
                  }
                  onClick={() => handleSort("worldRank")}
                >
                  #
                </TableSortLabel>
              </TableCell>
            ) : (
              <TableCell
                sortDirection={
                  sortConfig.key === "worldRank" ? sortConfig.direction : false
                }
              >
                <TableSortLabel
                  active={sortConfig.key === "worldRank"}
                  direction={
                    sortConfig.key === "worldRank"
                      ? sortConfig.direction
                      : "asc"
                  }
                  onClick={() => handleSort("worldRank")}
                >
                  World Rank
                </TableSortLabel>
              </TableCell>
            )}
            {/* <TableCell
              sx={{
                minWidth: { xs: "10px", sm: "20px" },
                padding: { xs: "2px", sm: "12px" },
                textAlign: "center",
              }}
            >
              🥇
            </TableCell>
            <TableCell
              sx={{
                minWidth: { xs: "10px", sm: "20px" },
                padding: { xs: "2px", sm: "12px" },
                textAlign: "center",
              }}
            >
              🥈
            </TableCell>
            <TableCell
              sx={{
                minWidth: { xs: "10px", sm: "20px" },
                padding: { xs: "2px", sm: "12px" },
                textAlign: "center",
              }}
            >
              🥉
            </TableCell> */}
          </TableHead>
          <TableBody>
            {filteredAthletes.map((athlete, index) => (
              <TableRow key={index}>
                <TableCell
                  sx={{
                    fontSize: { xs: "0.6rem", sm: "0.8rem" },
                    padding: { xs: "4px", sm: "6px" },
                    whiteSpace: "nowrap",
                  }}
                >
                  {athlete.name.split(" ")[0]}
                </TableCell>
                {isMobile ? (
                  <TableCell
                    sx={{
                      fontSize: { xs: "0.6rem", sm: "0.8rem" },
                      padding: { xs: "4px", sm: "6px" },
                      whiteSpace: "nowrap",
                    }}
                  >
                    {countryCodes[athlete.country] || athlete.country}{" "}
                    <img
                      src={athlete.flagImg}
                      alt={athlete.country}
                      style={{ width: 20, height: 15 }}
                    />
                  </TableCell>
                ) : (
                  <TableCell
                    sx={{
                      fontSize: { xs: "0.6rem", sm: "0.8rem" },
                      padding: { xs: "4px", sm: "6px" },
                      whiteSpace: "nowrap",
                    }}
                  >
                    {countryCodes[athlete.country] || athlete.country}
                  </TableCell>
                )}
                {isMobile ? (
                  <></>
                ) : (
                  <TableCell
                    sx={{
                      padding: { xs: "4px", sm: "6px" },
                      whiteSpace: "nowrap",
                    }}
                  >
                    {athlete.flagImg ? (
                      <img
                        src={athlete.flagImg}
                        alt={athlete.country}
                        style={{ width: 20, height: 15 }}
                      />
                    ) : (
                      "N/A"
                    )}
                  </TableCell>
                )}
                <TableCell
                  sx={{
                    fontSize: { xs: "0.6rem", sm: "0.8rem" },
                    padding: { xs: "4px", sm: "6px" },
                    whiteSpace: "nowrap",
                  }}
                >
                  {athlete.seasonBest || "N/A"}
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: { xs: "0.6rem", sm: "0.8rem" },
                    padding: { xs: "4px", sm: "6px" },
                    whiteSpace: "nowrap",
                  }}
                >
                  {athlete.personalBest || "N/A"}
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: { xs: "0.6rem", sm: "0.8rem" },
                    padding: { xs: "4px", sm: "6px" },
                    whiteSpace: "nowrap",
                  }}
                >
                  {athlete.worldRank || "N/A"}
                </TableCell>
                {/* <TableCell
                  sx={{
                    padding: { xs: "4px", sm: "6px" },
                    whiteSpace: "nowrap",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Radio
                        checked={picks.gold === athlete.name}
                        onChange={() => handlePickChange(athlete, "gold")}
                        size="small"
                      />
                    }
                    sx={{ margin: 0 }}
                  />
                </TableCell>
                <TableCell
                  sx={{
                    padding: { xs: "4px", sm: "6px" },
                    whiteSpace: "nowrap",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Radio
                        checked={picks.silver === athlete.name}
                        onChange={() => handlePickChange(athlete, "silver")}
                        size="small"
                      />
                    }
                    sx={{ margin: 0 }}
                  />
                </TableCell>
                <TableCell
                  sx={{
                    padding: { xs: "4px", sm: "6px" },
                    whiteSpace: "nowrap",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Radio
                        checked={picks.bronze === athlete.name}
                        onChange={() => handlePickChange(athlete, "bronze")}
                        size="small"
                      />
                    }
                    sx={{ margin: 0 }}
                  />
                </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      {selectedAthlete && (
        <Box sx={{ marginTop: 2, textAlign: "center" }}>
          <Typography variant="body2">
            Showing results for: <strong>{selectedAthlete.name}</strong>
          </Typography>
          <Button variant="contained" onClick={resetSearch}>
            Clear
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default EventPage;
