import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Link,
} from "@mui/material";
import { getUserPicks } from "../services/picksService";
import { getUsers } from "../services/leaderboardService";
import { auth } from "../firebase";
import LoadingSpinner from "../components/LoadingSpinner";

const LeaderboardPage = () => {
  const [leaderboard, setLeaderboard] = useState([]);
  const userId = auth.currentUser?.uid;
  const [loading, setLoading] = useState(true);

  function stringToColor(string) {
    let hash = 0;
    let i;
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = "#";
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
  }

  function stringAvatar(name) {
    const nameParts = name.split(" ");
    const initials =
      nameParts.length > 1
        ? `${nameParts[0][0]}${nameParts[1][0]}`
        : `${nameParts[0][0]}`;
    return {
      sx: {
        bgcolor: stringToColor(name),
        color: "#fff",
      },
      children: initials,
    };
  }

  const calculateScores = (userPicks, results) => {
    let totalGold = 0;
    let totalSilver = 0;
    let totalBronze = 0;
    let totalPoints = 0;

    userPicks.forEach((pick) => {
      const eventResult = results.find((result) => result.event === pick.event);
      if (eventResult) {
        const goldMedalists = Array.isArray(eventResult.gold)
          ? eventResult.gold
          : [eventResult.gold];
        const silverMedalists = Array.isArray(eventResult.silver)
          ? eventResult.silver
          : [eventResult.silver];
        const bronzeMedalists = Array.isArray(eventResult.bronze)
          ? eventResult.bronze
          : [eventResult.bronze];

        const isGoldCorrect = goldMedalists.includes(pick.gold);
        const isSilverCorrect = silverMedalists.includes(pick.silver);
        const isBronzeCorrect = bronzeMedalists.includes(pick.bronze);

        if (isGoldCorrect) totalGold += 1;
        if (isSilverCorrect) totalSilver += 1;
        if (isBronzeCorrect) totalBronze += 1;

        if (isGoldCorrect) totalPoints += 5;
        else if (
          silverMedalists.includes(pick.gold) ||
          bronzeMedalists.includes(pick.gold)
        )
          totalPoints += 1;

        if (isSilverCorrect) totalPoints += 3;
        else if (
          goldMedalists.includes(pick.silver) ||
          bronzeMedalists.includes(pick.silver)
        )
          totalPoints += 1;

        if (isBronzeCorrect) totalPoints += 2;
        else if (
          goldMedalists.includes(pick.bronze) ||
          silverMedalists.includes(pick.bronze)
        )
          totalPoints += 1;

        if (isGoldCorrect && isSilverCorrect && isBronzeCorrect) {
          totalPoints += 5; // Bonus points for correctly predicting all three medalists in the correct order
        }
      }
    });

    return { totalGold, totalSilver, totalBronze, totalPoints };
  };

  useEffect(() => {
    const fetchLeaderboardData = async () => {
      try {
        const users = await getUsers();
        const userPickPromises = users.map((user) => getUserPicks(user.id));
        const allUserPicks = await Promise.all(userPickPromises);

        const resultsResponse = await fetch(
          `/results.json?v=${new Date().getTime()}`
        );
        const resultsData = await resultsResponse.json();

        const processedLeaderboard = users
          .map((user, index) => {
            const userPicks = allUserPicks[index];
            const hasAllPicks =
              userPicks.length === 48 &&
              userPicks.every(
                (pick) => pick.gold && pick.silver && pick.bronze
              );
            if (hasAllPicks) {
              const { totalGold, totalSilver, totalBronze, totalPoints } =
                calculateScores(userPicks, resultsData);
              return {
                name: user.name,
                gold: totalGold,
                silver: totalSilver,
                bronze: totalBronze,
                totalPoints,
                avatar: user.avatar,
                id: user.id, // Add user id to check for the current user
              };
            }
            return null;
          })
          .filter(Boolean)
          .sort((a, b) => b.totalPoints - a.totalPoints); // Sort by total points

        setLeaderboard(processedLeaderboard);
      } catch (error) {
        console.error("Error fetching leaderboard data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchLeaderboardData();
  }, []);

  if (loading) {
    return <LoadingSpinner message="Loading leaderboard..." />;
  }

  return (
    <Box sx={{ padding: { xs: "8px", sm: "16px" } }}>
      <Typography
        variant="h4"
        gutterBottom
        sx={{ fontSize: { xs: "1.5rem", sm: "2rem" } }}
      >
        Leaderboard
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ width: "100%" }}>
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                sx={{
                  padding: "4px",
                  fontSize: { xs: "0.6rem", sm: "0.8rem" },
                }}
              >
                POS
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  padding: "4px",
                  fontSize: { xs: "0.6rem", sm: "0.8rem" },
                }}
              >
                NAME
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  padding: "4px",
                  fontSize: { xs: "0.6rem", sm: "0.8rem" },
                }}
              >
                GOLD
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  padding: "4px",
                  fontSize: { xs: "0.6rem", sm: "0.8rem" },
                }}
              >
                SILVER
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  padding: "4px",
                  fontSize: { xs: "0.6rem", sm: "0.8rem" },
                }}
              >
                BRONZE
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  padding: "4px",
                  fontSize: { xs: "0.6rem", sm: "0.8rem" },
                }}
              >
                TOTAL
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {leaderboard.map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  backgroundColor:
                    row.id === userId ? "rgba(0, 128, 0, 0.1)" : "inherit", // Highlight row if it matches the current user
                }}
              >
                <TableCell
                  align="center"
                  sx={{
                    padding: "4px",
                    fontSize: { xs: "0.6rem", sm: "0.8rem" },
                  }}
                >
                  {index + 1}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    padding: "4px",
                    fontSize: { xs: "1rem", sm: "1rem" },
                  }}
                >
                  <Link
                    href={`/user-picks/${row.id}`}
                    sx={{ color: "inherit" }}
                  >
                    {row.name}
                  </Link>
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    padding: "4px",
                    fontSize: { xs: "0.6rem", sm: "0.8rem" },
                    textAlign: "center",
                    verticalAlign: "middle",
                  }}
                >
                  <Avatar sx={{ bgcolor: "#FFD700", margin: "auto" }}>
                    {row.gold}
                  </Avatar>
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    padding: "4px",
                    fontSize: { xs: "0.6rem", sm: "0.8rem" },
                    textAlign: "center",
                    verticalAlign: "middle",
                  }}
                >
                  <Avatar sx={{ bgcolor: "#C0C0C0", margin: "auto" }}>
                    {row.silver}
                  </Avatar>
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    padding: "4px",
                    fontSize: { xs: "0.6rem", sm: "0.8rem" },
                    textAlign: "center",
                    verticalAlign: "middle",
                  }}
                >
                  <Avatar sx={{ bgcolor: "#CD7F32", margin: "auto" }}>
                    {row.bronze}
                  </Avatar>
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    padding: "4px",
                    fontSize: { xs: "0.6rem", sm: "0.8rem" },
                  }}
                >
                  {row.totalPoints}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default LeaderboardPage;
