import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

const ResultsPage = () => {
  const [results, setResults] = useState([]);

  useEffect(() => {
    const fetchResults = async () => {
      const response = await fetch(`/results.json?v=${new Date().getTime()}`); // Use the version query parameter for cache busting
      const data = await response.json();
      setResults(data);
    };

    fetchResults();
  }, []);

  const formatEventName = (name) => {
    let formattedName = name.toLowerCase();
    formattedName = formattedName.replace("MEN S-", "Men's ");
    formattedName = formattedName.replace("WOMEN S-", "Women's ");
    formattedName = formattedName.replace("4-x-", "4x");
    return formattedName.replace(/-/g, " ");
  };

  return (
    <Box sx={{ padding: { xs: "8px", sm: "16px" } }}>
      <Typography
        variant="h4"
        gutterBottom
        sx={{ fontSize: { xs: "1.5rem", sm: "2rem" } }}
      >
        Results
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ width: "100%" }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontSize: { xs: "0.6rem", sm: "0.8rem" } }}>
                Event
              </TableCell>
              <TableCell sx={{ fontSize: { xs: "0.6rem", sm: "0.8rem" } }}>
                Gold
              </TableCell>
              <TableCell sx={{ fontSize: { xs: "0.6rem", sm: "0.8rem" } }}>
                Silver
              </TableCell>
              <TableCell sx={{ fontSize: { xs: "0.6rem", sm: "0.8rem" } }}>
                Bronze
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {results.map((result, index) => (
              <TableRow key={index}>
                <TableCell sx={{ fontSize: { xs: "0.6rem", sm: "0.8rem" } }}>
                  {formatEventName(result.event).toUpperCase()}
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: { xs: "0.6rem", sm: "0.8rem" },
                    backgroundColor: "#FFD700",
                  }}
                >
                  {Array.isArray(result.gold)
                    ? result.gold.join(", ")
                    : result.gold}
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: { xs: "0.6rem", sm: "0.8rem" },
                    backgroundColor: "#C0C0C0",
                  }}
                >
                  {Array.isArray(result.silver)
                    ? result.silver.join(", ")
                    : result.silver}
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: { xs: "0.6rem", sm: "0.8rem" },
                    backgroundColor: "#CD7F32",
                  }}
                >
                  {Array.isArray(result.bronze)
                    ? result.bronze.join(", ")
                    : result.bronze}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ResultsPage;
