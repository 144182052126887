import { db } from "../firebase";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";

export const getUserById = async (userId) => {
  try {
    const userRef = doc(db, "users", userId);
    const userDoc = await getDoc(userRef);
    if (userDoc.exists()) {
      return userDoc.data();
    } else {
      throw new Error("User not found");
    }
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error;
  }
};

export const getUsers = async () => {
  try {
    const usersCollection = collection(db, "users");
    const usersSnapshot = await getDocs(usersCollection);
    const usersList = usersSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return usersList;
  } catch (error) {
    console.error("Error fetching users data:", error);
    throw error;
  }
};

export const copyUserPicks = async (sourceUserId, targetUserId) => {
  try {
    const picksCollection = collection(db, "picks");
    const sourcePicksQuery = query(
      picksCollection,
      where("userId", "==", sourceUserId)
    );
    const sourcePicksSnapshot = await getDocs(sourcePicksQuery);

    console.log(
      `Found ${sourcePicksSnapshot.size} picks for user ${sourceUserId}`
    );

    let successfulCopies = 0;
    let failedCopies = 0;

    await Promise.all(
      sourcePicksSnapshot.docs.map(async (pickDoc) => {
        try {
          const pickData = pickDoc.data();
          console.log("Pick data:", pickData);
          const newPick = {
            ...pickData,
            userId: targetUserId,
          };
          console.log("New pick data:", newPick);

          const targetPickRef = doc(picksCollection, pickDoc.id);
          await setDoc(targetPickRef, newPick);
          successfulCopies++;
        } catch (copyError) {
          console.error("Error copying pick:", copyError);
          failedCopies++;
        }
      })
    );

    console.log(`Successfully copied ${successfulCopies} picks`);
    if (failedCopies > 0) {
      console.error(`Failed to copy ${failedCopies} picks`);
    }
  } catch (error) {
    console.error("Error copying picks:", error);
    throw error;
  }
};
