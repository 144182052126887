import React from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";
import HorizontalLeaderboard from "../components/Leaderboard/HorizontalLeaderboard";
import CountdownTimer from "../components/CountdownTimer";

const HomePage = () => {
  const firstEventStartTime = "2024-08-01T01:30:00-05:00";

  return (
    <Box sx={{ padding: "16px" }}>
      {/* <HorizontalLeaderboard /> */}

      {/* <Box sx={{ marginTop: 4, textAlign: 'center' }}>
        <Typography variant="h5" gutterBottom>
          Time Remaining to Lock In Your Picks
        </Typography>
        <CountdownTimer targetDate={firstEventStartTime} />
      </Box> */}

      <Box sx={{ marginTop: 4 }}>
        <Typography variant="h5" gutterBottom>
          Scoring System Explained
        </Typography>

        <Box
          sx={{
            backgroundColor: "#f9f9f9",
            padding: "16px",
            borderRadius: "8px",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Medal Predictions
          </Typography>
          <List>
            <ListItem>
              <ListItemText primary="Gold Medal Guess: 5 Points" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Silver Medal Guess: 3 Points" />
            </ListItem>
            <ListItem>
              <ListItemText primary="Bronze Medal Guess: 2 Points" />
            </ListItem>
          </List>

          <Divider />

          <Typography variant="h6" gutterBottom sx={{ marginTop: 2 }}>
            Near Misses
          </Typography>
          <List>
            <ListItem>
              <ListItemText primary="Correct Athlete, Wrong Medal: 1 Point" />
            </ListItem>
          </List>

          <Divider />

          <Typography variant="h6" gutterBottom sx={{ marginTop: 2 }}>
            Bonus Points
          </Typography>
          <List>
            <ListItem>
              <ListItemText primary="All Three Medalists Correct in Position: 5 Bonus Points" />
            </ListItem>
          </List>
        </Box>

        <Box
          sx={{
            marginTop: 2,
            padding: "16px",
            borderRadius: "8px",
            backgroundColor: "#e0f7fa",
          }}
        >
          <Typography variant="body1">
            In this game, your challenge is to predict the medalists in each
            event accurately. Use the above scoring system to accumulate points.
            The more accurate your predictions, the higher your score!
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default HomePage;
