import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Button,
} from "@mui/material";
import { getUserPicks } from "../services/picksService";
import { getUserById } from "../services/userService";

const UserPicksPage = () => {
  const { userId } = useParams();
  const [user, setUser] = useState({});
  const [picks, setPicks] = useState([]);
  const [results, setResults] = useState([]);

  useEffect(() => {
    const fetchPicksAndResults = async () => {
      try {
        const userData = await getUserById(userId); // Fetch user data
        setUser(userData);
        const userPicks = await getUserPicks(userId);
        setPicks(userPicks);
        const resultsResponse = await fetch(
          `/results.json?v=${new Date().getTime()}`
        );
        const resultsData = await resultsResponse.json();
        setResults(resultsData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchPicksAndResults();
  }, [userId]);

  const formatEventName = (name) => {
    let formattedName = name.toLowerCase();
    formattedName = formattedName.replace("MEN S-", "Men's ");
    formattedName = formattedName.replace("WOMEN S-", "Women's ");
    formattedName = formattedName.replace("4-x-", "4x");
    return formattedName.replace(/-/g, " ");
  };

  const getCellStyle = (pick, result, medal) => {
    if (!result) return {};

    const medalists = Array.isArray(result[medal])
      ? result[medal]
      : [result[medal]];

    if (medalists.includes(pick)) {
      if (medal === "gold") return { backgroundColor: "#FFD700" };
      if (medal === "silver") return { backgroundColor: "#C0C0C0" };
      if (medal === "bronze") return { backgroundColor: "#CD7F32" };
    } else if (
      medalists.includes(pick) ||
      result.gold.includes(pick) ||
      result.silver.includes(pick) ||
      result.bronze.includes(pick)
    ) {
      return { backgroundColor: "#ADD8E6" };
    }
    return {};
  };

  const completedEvents = picks.filter((pick) =>
    results.find((result) => result.event === pick.event)
  );

  const upcomingEvents = picks.filter(
    (pick) => !results.find((result) => result.event === pick.event)
  );

  return (
    <Box sx={{ padding: { xs: "8px", sm: "16px" } }}>
      <Typography
        variant="h4"
        gutterBottom
        sx={{ fontSize: { xs: "1.5rem", sm: "2rem" } }}
      >
        {user.name}'s Picks
      </Typography>
      <Box sx={{ marginBottom: 2 }}>
        <Typography variant="h6">Legend:</Typography>
        <Grid container spacing={2}>
          <Grid item>
            <Box sx={{ backgroundColor: "#FFD700", padding: 1 }}>
              Correct Gold
            </Box>
          </Grid>
          <Grid item>
            <Box sx={{ backgroundColor: "#C0C0C0", padding: 1 }}>
              Correct Silver
            </Box>
          </Grid>
          <Grid item>
            <Box sx={{ backgroundColor: "#CD7F32", padding: 1 }}>
              Correct Bronze
            </Box>
          </Grid>
          <Grid item>
            <Box sx={{ backgroundColor: "#ADD8E6", padding: 1 }}>Near Miss</Box>
          </Grid>
        </Grid>
      </Box>
      <Typography variant="h5" gutterBottom>
        Completed Events
      </Typography>
      <TableContainer component={Paper} sx={{ marginBottom: 4 }}>
        <Table sx={{ width: "100%" }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontSize: { xs: "0.6rem", sm: "0.8rem" } }}>
                Event
              </TableCell>
              <TableCell sx={{ fontSize: { xs: "0.6rem", sm: "0.8rem" } }}>
                Gold
              </TableCell>
              <TableCell sx={{ fontSize: { xs: "0.6rem", sm: "0.8rem" } }}>
                Silver
              </TableCell>
              <TableCell sx={{ fontSize: { xs: "0.6rem", sm: "0.8rem" } }}>
                Bronze
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {completedEvents.map((pick, index) => {
              const result = results.find(
                (result) => result.event === pick.event
              );
              return (
                <TableRow key={index}>
                  <TableCell sx={{ fontSize: { xs: "0.6rem", sm: "0.8rem" } }}>
                    {formatEventName(pick.event).toUpperCase()}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: { xs: "0.6rem", sm: "0.8rem" },
                      ...getCellStyle(pick.gold, result, "gold"),
                    }}
                  >
                    {pick.gold}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: { xs: "0.6rem", sm: "0.8rem" },
                      ...getCellStyle(pick.silver, result, "silver"),
                    }}
                  >
                    {pick.silver}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: { xs: "0.6rem", sm: "0.8rem" },
                      ...getCellStyle(pick.bronze, result, "bronze"),
                    }}
                  >
                    {pick.bronze}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography variant="h5" gutterBottom>
        Upcoming Events
      </Typography>
      <Button
        variant="contained"
        color="primary"
        component={Link}
        to={`/compare/${userId}`}
      >
        Compare Picks
      </Button>

      <TableContainer component={Paper}>
        <Table sx={{ width: "100%" }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontSize: { xs: "0.6rem", sm: "0.8rem" } }}>
                Event
              </TableCell>
              <TableCell sx={{ fontSize: { xs: "0.6rem", sm: "0.8rem" } }}>
                Gold
              </TableCell>
              <TableCell sx={{ fontSize: { xs: "0.6rem", sm: "0.8rem" } }}>
                Silver
              </TableCell>
              <TableCell sx={{ fontSize: { xs: "0.6rem", sm: "0.8rem" } }}>
                Bronze
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {upcomingEvents.map((pick, index) => (
              <TableRow key={index}>
                <TableCell sx={{ fontSize: { xs: "0.6rem", sm: "0.8rem" } }}>
                  {formatEventName(pick.event).toUpperCase()}
                </TableCell>
                <TableCell sx={{ fontSize: { xs: "0.6rem", sm: "0.8rem" } }}>
                  {pick.gold}
                </TableCell>
                <TableCell sx={{ fontSize: { xs: "0.6rem", sm: "0.8rem" } }}>
                  {pick.silver}
                </TableCell>
                <TableCell sx={{ fontSize: { xs: "0.6rem", sm: "0.8rem" } }}>
                  {pick.bronze}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default UserPicksPage;
