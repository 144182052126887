import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, signInAnonymously, onAuthStateChanged, setPersistence, browserLocalPersistence } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyALetMUn7Eh34Y9j9KIKDdbyF4vZ6RzBEo",
  authDomain: "olympics-337da.firebaseapp.com",
  projectId: "olympics-337da",
  storageBucket: "olympics-337da.appspot.com",
  messagingSenderId: "106393342851",
  appId: "1:106393342851:web:3575143555d3f4eef45cb6",
  measurementId: "G-15FSX0SXG2"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

const signInAnonymouslyToFirebase = async () => {
  try {
    await signInAnonymously(auth);
    console.log('Signed in anonymously');
  } catch (error) {
    console.error('Error signing in anonymously:', error);
  }
};

// Set persistence mode to local
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    // Now sign in anonymously
    return signInAnonymously(auth);
  })
  .catch((error) => {
    console.error('Error setting persistence and signing in:', error);
  });

// Listen for auth state changes
onAuthStateChanged(auth, user => {
  if (user) {
    console.log('User ID:', user.uid); // Log the user ID
  } else {
    console.log('User is signed out');
  }
}); 

export { db, auth, signInAnonymouslyToFirebase };