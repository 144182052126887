import { db } from "../firebase";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  query,
  orderBy,
} from "firebase/firestore";

// Fetch leaderboard data
export const getLeaderboardData = async () => {
  try {
    const leaderboardQuery = query(
      collection(db, "leaderboard"),
      orderBy("totalPoints", "desc")
    );
    const querySnapshot = await getDocs(leaderboardQuery);
    const leaderboardData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return leaderboardData;
  } catch (error) {
    console.error("Error fetching leaderboard data:", error);
    throw error;
  }
};

// Add or update a user in the leaderboard
export const updateUserInLeaderboard = async (userId, userData) => {
  try {
    const userRef = doc(db, "leaderboard", userId);
    await updateDoc(userRef, userData);
  } catch (error) {
    console.error("Error updating user in leaderboard:", error);
    throw error;
  }
};

export const getUsers = async () => {
  const usersCol = collection(db, "users");
  const userSnapshot = await getDocs(usersCol);
  const userList = userSnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  return userList;
};

// Add a new user to the leaderboard
export const addUserToLeaderboard = async (userData) => {
  try {
    await addDoc(collection(db, "leaderboard"), userData);
  } catch (error) {
    console.error("Error adding user to leaderboard:", error);
    throw error;
  }
};
