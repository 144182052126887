import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Paper,
  Divider,
  IconButton,
  Collapse,
} from "@mui/material";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import dayjs from "dayjs";

const SchedulePage = () => {
  const [schedule, setSchedule] = useState([]);
  const [collapsedDays, setCollapsedDays] = useState({});
  const [closestEventIndex, setClosestEventIndex] = useState(null);

  useEffect(() => {
    fetch("/schedule.json")
      .then((response) => response.json())
      .then((data) => {
        console.log("Fetched schedule data:", data); // Debug statement
        setSchedule(data);
        handleClosestEventHighlight(data);
      })
      .catch((error) => console.error("Error fetching events data:", error));
  }, []);

  const handleToggleCollapse = (index) => {
    setCollapsedDays((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const handleClosestEventHighlight = (data) => {
    const now = dayjs();
    let closestEventIndex = null;
    let closestEventTime = null;

    data.forEach((day, dayIndex) => {
      day.events.forEach((event, eventIndex) => {
        const eventTime = dayjs(`${day.date} ${event.time}`);
        if (eventTime.isAfter(now)) {
          if (!closestEventTime || eventTime.isBefore(closestEventTime)) {
            closestEventTime = eventTime;
            closestEventIndex = { dayIndex, eventIndex };
          }
        }
      });
    });

    setClosestEventIndex(closestEventIndex);

    if (closestEventIndex) {
      setCollapsedDays((prev) => ({
        ...prev,
        [closestEventIndex.dayIndex]: false,
      }));
    }
  };

  useEffect(() => {
    const now = dayjs();
    const initialCollapsedDays = {};

    schedule.forEach((day, index) => {
      const isPastDay = dayjs(day.date).isBefore(now, "day");
      const isCurrentDay = dayjs(day.date).isSame(now, "day");
      initialCollapsedDays[index] =
        isPastDay || (!isCurrentDay && !collapsedDays[index]);
    });

    setCollapsedDays(initialCollapsedDays);
  }, [schedule]);

  return (
    <Box sx={{ padding: "16px" }}>
      <Typography variant="h4" gutterBottom>
        Track & Field Schedule
      </Typography>
      {schedule.map((day, dayIndex) => {
        const isCollapsed = collapsedDays[dayIndex];

        return (
          <Box key={dayIndex} sx={{ marginBottom: "16px" }}>
            <Typography
              variant="h5"
              sx={{
                backgroundColor: "#1976d2",
                color: "white",
                padding: "8px",
                cursor: "pointer",
              }}
              onClick={() => handleToggleCollapse(dayIndex)}
            >
              {day.date} {isCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </Typography>
            <Collapse in={!isCollapsed}>
              <Paper sx={{ padding: "16px" }}>
                <List>
                  {day.events.map((event, eventIndex) => {
                    const isClosestEvent =
                      closestEventIndex?.dayIndex === dayIndex &&
                      closestEventIndex?.eventIndex === eventIndex;
                    return (
                      <React.Fragment key={eventIndex}>
                        <ListItem
                          sx={{
                            backgroundColor: isClosestEvent
                              ? "#FFF176"
                              : "inherit",
                          }}
                        >
                          <ListItemText
                            primary={
                              <Typography variant="subtitle1">
                                {event.time}
                              </Typography>
                            }
                            secondary={
                              <Typography variant="body2" color="textSecondary">
                                {event.description}
                              </Typography>
                            }
                          />
                          <IconButton>
                            <BookmarkBorderIcon />
                          </IconButton>
                          <IconButton>
                            <MoreVertIcon />
                          </IconButton>
                        </ListItem>
                        {eventIndex < day.events.length - 1 && <Divider />}
                      </React.Fragment>
                    );
                  })}
                </List>
              </Paper>
            </Collapse>
          </Box>
        );
      })}
    </Box>
  );
};

export default SchedulePage;
