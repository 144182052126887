import React from 'react';
import UserList from '../components/Players/UserList';

function PlayersPage() {
  return (
    <div>
      <UserList />
    </div>
  );
}

export default PlayersPage;
