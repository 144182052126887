import { doc, getDoc, setDoc, updateDoc, addDoc, collection } from 'firebase/firestore';
import { db } from '../firebase';

// Function to get picks for a user
export const getUserPicks = async (userId) => {
    const userDoc = await getDoc(doc(db, 'picks', userId));
    if (userDoc.exists()) {
        return userDoc.data().picks;
    } else {
        return [];
    }
};

// Function to save or update picks for a user
export const saveUserPicks = async (userId, event, picks) => {
    const userDocRef = doc(db, 'picks', userId);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
        const userData = userDoc.data();
        const updatedPicks = userData.picks.map(pick =>
            pick.event === event ? { event, ...picks } : pick
        );

        // If the event does not exist in picks, add it
        if (!updatedPicks.some(pick => pick.event === event)) {
            updatedPicks.push({ event, ...picks });
        }

        await updateDoc(userDocRef, { picks: updatedPicks });
    } else {
        await setDoc(userDocRef, { userId, picks: [{ event, ...picks }] });
    }
};

export const saveUser = async (userId, name) => {
    try {
        await addDoc(collection(db, 'users'), {
            userId,
            name,
        });
    } catch (e) {
        console.error('Error adding user: ', e);
    }
};
