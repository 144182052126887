import React, { useState, useEffect } from "react";
import { AppBar, Toolbar, IconButton, Box } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { styled } from "@mui/system";
import logo from "../assets/Olympics.svg.png";
import nike from "../assets/Logo_NIKE.svg.png";
import omega from "../assets/Omega_Logo.svg.png";
import audi from "../assets/Audi-Logo_2016.svg.png";

const sponsors = [
  { src: nike, alt: "Sponsor 1" },
  { src: audi, alt: "Sponsor 2" },
  { src: omega, alt: "Sponsor 3" },
];

const RotatingSponsorIcon = styled("img")({
  height: "auto",
  width: 60,
});

const TopNavBar = ({ onProfileClick }) => {
  const [currentSponsor, setCurrentSponsor] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSponsor((prev) => (prev + 1) % sponsors.length);
    }, 6000); // Change sponsor every 6 seconds
    return () => clearInterval(interval);
  }, []);

  return (
    <AppBar position="fixed" color="default" sx={{ top: 0, bottom: "auto" }}>
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <IconButton
          sx={{ width: "60px" }}
          edge="start"
          color="inherit"
          onClick={onProfileClick}
        >
          <AccountCircleIcon />
        </IconButton>
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={logo} alt="Logo" style={{ height: 40, width: "auto" }} />
        </Box>
        <RotatingSponsorIcon
          src={sponsors[currentSponsor].src}
          alt={sponsors[currentSponsor].alt}
        />
      </Toolbar>
    </AppBar>
  );
};

export default TopNavBar;
