import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import EventIcon from "@mui/icons-material/Event";
import PeopleIcon from "@mui/icons-material/People";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import FeedIcon from "@mui/icons-material/Feed";

const NavBar = () => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        navigate("/");
        break;
      case 1:
        navigate("/events");
        break;
      case 2:
        navigate("/leaderboard");
        break;
      case 3:
        navigate("/schedule");
        break;
      case 4:
        navigate("/results");
        break;
      default:
        break;
    }
  };

  return (
    <BottomNavigation
      value={value}
      onChange={handleChange}
      showLabels
      sx={{
        position: "fixed",
        bottom: 0,
        width: "100%",
        zIndex: 1000,
        backgroundColor: "#FFFFFF", // Background color to match the app
        overflowX: "auto", // Allow horizontal scroll if necessary
      }}
    >
      <BottomNavigationAction label="Home" icon={<HomeIcon />} />
      <BottomNavigationAction label="Events" icon={<EventIcon />} />
      {/* <BottomNavigationAction label="Players" icon={<PeopleIcon />} /> */}
      <BottomNavigationAction label="Leaderboard" icon={<LeaderboardIcon />} />
      <BottomNavigationAction label="Schedule" icon={<CalendarMonthIcon />} />
      <BottomNavigationAction label="Results" icon={<FeedIcon />} />
    </BottomNavigation>
  );
};

export default NavBar;
