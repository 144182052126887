import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import EventsPage from "./pages/EventsPage";
import PlayersPage from "./pages/PlayersPage";
import LeaderboardPage from "./pages/LeaderboardPage";
import NavBar from "./components/NavBar";
import { auth, signInAnonymouslyToFirebase, db } from "./firebase";
import { addDoc, collection, doc, setDoc } from "firebase/firestore";
import TopNavBar from "./components/TopNavBar";
import EventPage from "./pages/EventPage";
import SchedulePage from "./pages/SchedulePage";
import {
  Box,
  TextField,
  Button,
  Typography,
  Avatar,
  Container,
  Paper,
} from "@mui/material";
import logo from "./assets/Olympics.svg.png";
import "./App.css";
import ResultsPage from "./pages/ResultsPage";
import UserPicksPage from "./pages/UserPicksPage";
import LeaderboardTestPage from "./pages/LeaderboardTestPage";
import ComparisonPage from "./pages/ComparisonPage";

function App() {
  const [name, setName] = useState("");
  const [isNameSet, setIsNameSet] = useState(false);
  const [isAuthReady, setIsAuthReady] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setIsAuthReady(true);
        const storedName = localStorage.getItem("userName");
        if (storedName) {
          setName(storedName);
          setIsNameSet(true);
        }
      } else {
        signInAnonymouslyToFirebase(auth)
          .then(() => {
            setIsAuthReady(true);
          })
          .catch((error) => {
            console.error("Error during anonymous sign-in:", error);
          });
      }
    });
    return () => unsubscribe();
  }, []);

  const handleNameSubmit = async (e) => {
    e.preventDefault();
    localStorage.setItem("userName", name);
    setIsNameSet(true);

    // Save user info to Firestore
    if (auth.currentUser) {
      try {
        await setDoc(doc(db, "users", auth.currentUser.uid), {
          userId: auth.currentUser.uid,
          name,
          points: 0,
        });
      } catch (error) {
        console.error("Error adding user to Firestore:", error);
      }
    }
  };

  const handleProfileClick = () => {
    setIsNameSet(false);
  };

  if (!isAuthReady) {
    return <div>Loading...</div>;
  }

  if (!isNameSet) {
    return (
      <Container component="main" maxWidth="xs" sx={{ marginTop: 8 }}>
        <Paper elevation={3} sx={{ padding: 3, borderRadius: 2 }}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <img src={logo} alt="App Logo" className="logo" />
            <Typography
              variant="h5"
              gutterBottom
              sx={{ fontWeight: "bold", textAlign: "center" }}
            >
              Welcome to Olympic Picks!
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ color: "text.secondary", mb: 2, textAlign: "center" }}
            >
              Enter your name or nickname to start making your medal picks
            </Typography>
            <Box
              component="form"
              onSubmit={handleNameSubmit}
              sx={{ mt: 1, width: "100%" }}
            >
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="name"
                label="Your Name or Nickname"
                name="name"
                autoComplete="name"
                autoFocus
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={{
                  mt: 3,
                  mb: 2,
                  backgroundColor: "primary.main",
                  "&:hover": {
                    backgroundColor: "primary.dark",
                  },
                }}
              >
                Start Picking
              </Button>
            </Box>
          </Box>
        </Paper>
      </Container>
    );
  }

  return (
    <Router>
      <TopNavBar onProfileClick={handleProfileClick} />
      <div style={{ paddingBottom: "56px", paddingTop: "72px" }}>
        {" "}
        {/* Ensure space for the BottomNav */}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/events" element={<EventsPage />} />
          <Route path="/event/:eventName" element={<EventPage />} />
          <Route path="/players" element={<PlayersPage />} />
          <Route path="/schedule" element={<SchedulePage />} />
          <Route path="/leaderboard" element={<LeaderboardPage />} />
          <Route path="/results" element={<ResultsPage />} />
          <Route path="/user-picks/:userId" element={<UserPicksPage />} />
          <Route path="teste" element={<LeaderboardTestPage />} />
          <Route path="/compare/:userId" element={<ComparisonPage />} />
        </Routes>
      </div>
      <NavBar />
    </Router>
  );
}

export default App;
