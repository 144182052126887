// hooks/useConsolidateRelayTeams.js

import { useState, useEffect } from 'react';

export const useConsolidateRelayTeams = (athletes, eventName) => {
    const [consolidatedAthletes, setConsolidatedAthletes] = useState([]);

    useEffect(() => {
        if (eventName.toLowerCase().includes('relay')) {
            const teamsByCountry = {};

            athletes.forEach((athlete) => {
                if (!teamsByCountry[athlete.country]) {
                    teamsByCountry[athlete.country] = {
                        country: athlete.country,
                        flagImg: athlete.flagImg,
                        athletes: [],
                    };
                }
                teamsByCountry[athlete.country].athletes.push(athlete.name);
            });

            const consolidatedData = Object.values(teamsByCountry).map((team) => ({
                name: team.country,  // Using country name as team name
                country: team.country,
                flagImg: team.flagImg,
            }));

            setConsolidatedAthletes(consolidatedData);
        } else {
            setConsolidatedAthletes(athletes);
        }
    }, [athletes, eventName]);

    return consolidatedAthletes;
};
