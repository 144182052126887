import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import styled from "styled-components";

dayjs.extend(duration);

const TimerContainer = styled.div`
  font-family: "Digital-7 Mono", sans-serif;
  font-size: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  max-width: 100%;
  width: fit-content;

  @media (max-width: 600px) {
    font-size: 1.5rem; /* Adjust the size for mobile */
    padding: 8px;
  }
`;

const CountdownTimer = ({ targetDate }) => {
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [countdownOver, setCountdownOver] = useState(false);

  useEffect(() => {
    const startTime = dayjs(targetDate);
    updateCountdown(startTime);

    const interval = setInterval(() => {
      updateCountdown(startTime);
    }, 1000);

    return () => clearInterval(interval);
  }, [targetDate]);

  const updateCountdown = (startTime) => {
    const now = dayjs();
    if (startTime.isAfter(now)) {
      const timeLeft = dayjs.duration(startTime.diff(now));
      setTimeRemaining({
        days: timeLeft.days(),
        hours: timeLeft.hours(),
        minutes: timeLeft.minutes(),
        seconds: timeLeft.seconds(),
      });
    } else {
      setCountdownOver(true);
      setTimeRemaining(null);
    }
  };

  return (
    <Box
      sx={{
        textAlign: "center",
        backgroundColor: "#000",
        color: "#fff",
        padding: { xs: "8px", sm: "16px" },
        borderRadius: "8px",
        margin: "0 auto",
        maxWidth: "100%",
        width: "fit-content",
        fontFamily: "Digital-7, sans-serif",
      }}
    >
      {countdownOver ? (
        <Typography variant="h6" color="error">
          The picks are now locked!
        </Typography>
      ) : (
        timeRemaining && (
          <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
            <CountdownUnit value={timeRemaining.days} unit="Days" />
            <CountdownUnit value={timeRemaining.hours} unit="Hours" />
            <CountdownUnit value={timeRemaining.minutes} unit="Minutes" />
            <CountdownUnit value={timeRemaining.seconds} unit="Seconds" />
          </Box>
        )
      )}
    </Box>
  );
};

const CountdownUnit = ({ value, unit }) => (
  <Box sx={{ textAlign: "center", minWidth: "60px" }}>
    <Typography
      variant="h4"
      component="div"
      sx={{ fontFamily: "Digital-7, sans-serif" }}
    >
      {value}
    </Typography>
    <Typography variant="caption" component="div">
      {unit}
    </Typography>
  </Box>
);

export default CountdownTimer;
