import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';

const users = [
  { name: 'User1', picks: ['Bolt', 'Blake', 'Gatlin'] },
  // Add more user data
];

function UserList() {
  return (
    <div>
      {users.map((user, index) => (
        <Card key={index} sx={{ marginBottom: 2 }}>
          <CardContent>
            <Typography variant="h5">{user.name}</Typography>
            <Typography variant="body2">Picks: {user.picks.join(', ')}</Typography>
          </CardContent>
        </Card>
      ))}
    </div>
  );
}

export default UserList;
